<template>
<div class="h-screen flex w-full bg-img">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/HoltEstateLogowhite.png" class="mx-auto mb-2 max-w-full custom-logo-size">
        <h6 class="sm:mx-0 mb-base text-xl text-white">Please Wait...</h6>
        <vs-terms></vs-terms>
        <h6 class="sm:mx-0 text-lg text-white">Version: {{ currentVersion }}</h6>
    </div>
</div>
</template>

<script>
import * as Msal from "@azure/msal-browser";

import {
    BaseConfig
} from '../../store/api/config'

import {
    version
} from '../../../package.json';

export default {
    data() {
        return {
            currentVersion: version
        }
    },
    created() {
        this.setupClientApplication();
        this.signup();
    },
    methods: {
        signup() {
            var request = {
                redirectStartPage: `${BaseConfig.AppUrl}completesignup`,
                scopes: BaseConfig.AppScope
            }
            this.$store.dispatch("setIsProgressing", true);
            this.app.loginRedirect(request);
        },
        setupClientApplication() {
            this.app = new Msal.PublicClientApplication({
                auth: {
                    clientId: `${BaseConfig.AppClientID}`,
                    authority: `${BaseConfig.AppSignUpFlow}`,
                    knownAuthorities: [`${BaseConfig.AppSignUpFlow}`],
                    validateAuthority: false,
                    navigateToLoginRequestUrl: true,
                    redirectUri: `${BaseConfig.AppUrl}completesignup`,
                    postLogoutRedirectUri: `${BaseConfig.AppUrl}login`,
                },
                cache: {
                    cacheLocation: "sessionStorage",
                    storeAuthStateInCookie: true,
                    secureCookies: true
                }
            });
        },
    }
}
</script>

<style scoped>
.custom-logo-size {
    width: 40rem;
}
</style>
